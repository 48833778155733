import React from 'react'
import ProductTemporalXBG from './productTeporalXBGtop'
import styled from 'styled-components'
import Header from '../../header/header'
import media from '../../media'

import Performance from "./features/performance"
import Compatibility from "./features/compatibility"
import SixShills from './features/sixShills'
import TemporalXtabs from './temporalXtabs'
import TemporalXContact from './temporalXcontact'


import TemporalXlogo from '../../../images/product/temporalX/xlogo.svg'
import Faster from '../../../images/product/temporalX/icons/10x.svg'
import Seamless from '../../../images/product/temporalX/icons/seamless.svg'
import Growth from '../../../images/product/temporalX/icons/growth.svg'




const StyledProductContainer = styled.div`
display:flex;
flex-direction:column;
justify-content:space-around;
padding: 10% 8%;
`
const StyledTemporalHeader = styled.h1`
${media.mobileS`
width:100%;
font-size:2rem;
margin-top:100px;
`}
${media.laptop`
margin-top:0;

`}
color:orange;


`
const StyledAboutSubHeader = styled.h2`
text-transform:capitalize;
${
    media.mobileS`
   font-size:1.5rem;
    `
  }
  ${
    media.tablet`
   font-size:2rem;
    `
  }

color:white;

padding-top:5%;
`
const StyledAboutText = styled.p`

${
    media.mobileS`
   width:96%;
   font-size:0.9rem;
    `
  }
  ${
    media.tablet`
   width:80%;
   font-size:1rem;
    `
  }
  
color:white;
font-family:sans-serif;

`
const StyledTextDecal = styled.div`
background-color:orange;
width:15%;
height:4px;
/* margin-bottom:5%; */
`
const StyledImageContainer = styled.div`
width:80%;
${media.mobileS`
padding-top:10%;
padding-bottom:15%;
margin:auto;
`}
${media.laptop`
padding-top:5%;
padding-right:5%;
padding-bottom:0%;

`}

`
const StyledProductSctionContainer = styled.div`
display:flex;
justify-content:space-around;
${media.mobileS`
flex-direction:column;
`}
${media.laptop`
flex-direction:row;
width:90%;
margin:auto;
`}
`
const StyledShillsContainer = styled.ul`
display:flex;
justify-content:space-around;
${media.mobileS`
flex-direction:column;
align-items:center;
`}
${media.laptop`
flex-direction:row;
padding-top:5%;
align-items:baseline;
`}
`
const StyledShill = styled.li`
flex-direction:column;
justify-content:space-between;
align-items:flex-end;
text-align:center;
list-style:none;
${media.mobileS`
padding-bottom:10%;
`}
${media.laptop`
padding-bottom:0;
`}
`

const StyledShillTitle = styled.h2`
font-family:sans-serif;
color:orange;

${media.laptop`
font-size:1.2rem;

`}
`



const productTemporal = () => (
    
       <ProductTemporalXBG>
        <Header/>
        <StyledProductSctionContainer>
          <StyledProductContainer>
            <StyledTemporalHeader >
            Your Enterprise IPFS Client
            </StyledTemporalHeader >
            <StyledTextDecal/>
            <StyledAboutSubHeader/>
              <StyledAboutText>
              A faster, more scalable IPFS implementation that will quickly and safely let your business meet it's data demands while still maintaining interoperability with the rest of the IPFS network.<br/><br/>With no dependencies on go-ipfs, TemporalX has been built from the ground up to be lean and efficient without sacrificing functionality.              </StyledAboutText>
          </StyledProductContainer>
          <StyledImageContainer>
           <img src={TemporalXlogo} />
          </StyledImageContainer>
        </StyledProductSctionContainer>
        <StyledShillsContainer>
          <StyledShill>
            <img src={Faster} />
            <StyledShillTitle> Up to 10x Faster</StyledShillTitle>
          </StyledShill>
          <StyledShill>
            <img src={Seamless} />
            <StyledShillTitle> Seamless Integration</StyledShillTitle>
          </StyledShill>
          <StyledShill>
            <img src={Growth} />
            <StyledShillTitle> Engineered For Growth </StyledShillTitle>
          </StyledShill>
        </StyledShillsContainer>
        <Performance/>
        <Compatibility/>
        <SixShills/>
        <TemporalXtabs/>
        <TemporalXContact/>

       </ProductTemporalXBG>
    )


export default productTemporal
