import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import tabslist from "../../../images/product/temporalX/tabslist.png";

import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import QueueIcon from '@material-ui/icons/Queue';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // backgroundColor: theme.palette.background.paper,
  },
}));

export default function SimpleTabs() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs value={value} onChange={handleChange} aria-label="temporalx tabs" style={{justifyContent:"center"}}  variant="fullWidth" indicatorColor='secondary'>
          <Tab label="What's Included" {...a11yProps(0)} icon={<ShoppingCartIcon/>}/>
          <Tab label="Benefits" {...a11yProps(1)} icon={<ThumbUpIcon />}/>
          <Tab label="Support" {...a11yProps(2)} icon={<LiveHelpIcon />}/>
          <Tab label="Add-on" {...a11yProps(3)} icon={<QueueIcon />} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0} style={{backgroundColor:"#212121",paddingBottom:"10%"}}>
        <ul style={{color:"white", listStyleImage:`url(${tabslist})` }}>
            <li>TemporalX standard license price of $299US (Bulk pricing available). </li>
            <li>3 Nodes that operate for life.  </li>
            <li>1-year license to upgrades and new features as rolled out. </li>
            <li>Technical Support - 12 Hours/Day Monday to Friday, hours of operation: 11am- 11pm PDT/PST.</li>
            <li>Subject to change (existing clients license will not be effected without consent).</li>
        </ul>
      </TabPanel>
      <TabPanel value={value} index={1} style={{backgroundColor:"#212121",paddingBottom:"10%"}}>
      <ul style={{color:"white", listStyleImage:`url(${tabslist})`}}>
            <li>No extra expensive hardware needed because X nodes bring scalable IPFS to any and all existing hardware. </li>
            <li>Heavily tested and profiled to achieve maximal performance with minimal resources.</li>
            <li>Consistent performance across all data sizes. </li>
            <li>Eliminates slow performance, crashes, and memory leaks of your big data workloads on IPFS.</li>
        </ul>
      </TabPanel>
      <TabPanel value={value} index={2} style={{backgroundColor:"#212121",paddingBottom:"10%"}}>
      <ul style={{color:"white", listStyleImage:`url(${tabslist})`   }}>
            <li>Unlimited number of support requests.</li>
            <li>Online access to documentation and technical resources.</li>
            <li>Response method email/private support chat.</li>
            <li>Root cause analysis only available with business-critical issues. Our team will classify what is "critical".</li>
            <li>Default Target Response Time 48 hours or less.</li>
        </ul>
      </TabPanel>
      <TabPanel value={value} index={3} style={{backgroundColor:"#212121",paddingBottom:"10%"}}>
      <ul style={{color:"white", listStyleImage:`url(${tabslist})`   }}>
            <li>Hosting options out of our IPFS dedicated data center. </li>
            <li>Support package upgrade for quicker response time. </li>
            <li>Consultation for custom public/private solutions. </li>
            <li>Contracting services for developing custom solutions/applications.</li>         
        </ul>
      </TabPanel>
    </div>
  );
}