import React from 'react'
import styled from 'styled-components'
import media from '../../../media'

import ProductTemporalXBG from '../../temporal/productTeporalBGbottom'

import Library from '../../../../images/product/temporalX/icons/library.svg'
import Datastore from '../../../../images/product/temporalX/icons/datastore.svg'
import Tune from '../../../../images/product/temporalX/icons/tune.svg'
import Nopin from '../../../../images/product/temporalX/icons/nopin.svg'
import Dashboard from '../../../../images/product/temporalX/icons/dashboard.svg'
import Faster from '../../../../images/product/temporalX/icons/3x.svg'
import Temporalx from '../../../../images/product/temporalX/icons/thex.svg'
import Checkmark from '../../../../images/product/temporalX/icons/checkmark.svg'


const StyledShillsContainer = styled.ul`
display:flex;
justify-content:space-around;
align-items:flex-start;
flex-wrap:wrap;
width:90%;
margin:auto;
padding:5% 0;
${media.mobileS`
flex-direction:column;
`}
${media.laptop`
flex-direction:row;
`}

`
const StyledShill = styled.li`
flex-direction:column;
justify-content:space-between;
align-items:flex-start;
list-style:none;
padding-top:5%;
${media.mobileS`
width:90%;
margin:auto;
padding-bottom:10%;
`}
${media.laptop`
width:30%;
margin:0;
padding-bottom:0;
`}
`
const StyledIconContainer = styled.div`
width:60px;
`
const StyledFasterIconContainer = styled.div`
width:100px;
`
const StyledShillTitle = styled.h2`
color:orange;
font-family:sans-serif;
${media.mobileS`
font-size:1rem;
padding-top:5%;
width:100%;
`}
${media.laptop`
font-size:1rem;
padding-top:5%;
`}
`
const StyledShillText = styled.p`
color:white;
font-family:sans-serif;
font-size:.9rem;
width:90%;

`
const StyledCheckmarks = styled.div`
display:flex;
flex-direction:column;
justify-content:flex-start;
align-items:flex-start;

${media.mobileS`
  width:100%;
`}
${media.laptop`
padding-right:20%;
`}




`
const StyledCheckmark = styled.a`
display:flex;
flex-direction:row;
justify-content:space-between;
align-items:stretch;
width:100%;
color:green;
`
const StyledCheckmarkText = styled.p`
${ media.mobileS`
   width:85%;
   font-size:0.9rem;
    `
  }
  ${media.tablet`
    width:80%;
   font-size:1rem;
    `
  }
    ${media.laptopL`
    width:85%;
   font-size:1rem;
    `
  }
  
color: #3DA639;
font-family:sans-serif;
`
const StyledCheckmarkContainer = styled.div`
width:35px;
` 

const StyledLink = styled.a`
color:orange;
font-family:sans-serif;
${ media.mobileS`
padding:2% 0%;
    `}
${media.tablet`
  padding:2% 0%;
    `
  }

`
const sixShills = () => {
    return (
        <ProductTemporalXBG>
        <StyledShillsContainer>
            <StyledShill>
                <StyledIconContainer>
                    <img src={Library}/>
                </StyledIconContainer>
                <StyledShillTitle>Forked Optimized Libraries</StyledShillTitle>            
                <StyledCheckmarks>
                    <StyledCheckmark>
                        <StyledCheckmarkContainer>
                             <img src={Checkmark}/>
                        </StyledCheckmarkContainer>
                        <StyledCheckmarkText>
                        go-libp2p-kad-dht
                        </StyledCheckmarkText>
                    </StyledCheckmark>
                    <StyledCheckmark>
                        <StyledCheckmarkContainer>
                            <img src={Checkmark}/>
                        </StyledCheckmarkContainer>
                        <StyledCheckmarkText>
                        go-libp2p-record
                        </StyledCheckmarkText>
                    </StyledCheckmark>
                    <StyledCheckmark>
                        <StyledCheckmarkContainer>
                            <img src={Checkmark}/>
                        </StyledCheckmarkContainer>
                        <StyledCheckmarkText>
                        go-libp2p-swarm
                        </StyledCheckmarkText>
                    </StyledCheckmark>
                    <StyledCheckmark>
                        <StyledCheckmarkContainer>
                            <img src={Checkmark}/>
                        </StyledCheckmarkContainer>
                        <StyledCheckmarkText>
                        go-ipfs-blockstore 
                        </StyledCheckmarkText>
                    </StyledCheckmark>
                    <StyledCheckmark>
                        <StyledCheckmarkContainer>
                            <img src={Checkmark}/>
                        </StyledCheckmarkContainer>
                        <StyledCheckmarkText>
                        IPNS/namesys 
                        </StyledCheckmarkText>
                    </StyledCheckmark>

                </StyledCheckmarks>

            </StyledShill>
            <StyledShill>
            <StyledIconContainer style={{paddingBottom:"2%"}}>
                    <img src={Datastore}/>
                </StyledIconContainer>
                <StyledShillTitle>Multi-Datastore Capabilities</StyledShillTitle>  
                <StyledShillText>
                Leverage multiple datastore types for different components, allowing you to take full advantage of your hardware. <br/><br/>For example, you can leverage badger for your blockstore datastore, while leveraging leveldb for your peerstore datastore. You can then leverage a pebble datastore for your DHT. 
                    </StyledShillText>        
            </StyledShill>
          
            <StyledShill>
            <StyledFasterIconContainer style={{paddingBottom:"3%",paddingTop:"5%"}}>
            
                    <img src={Faster}/>
                </StyledFasterIconContainer >
                <StyledShillTitle>S3 on Top IPFS</StyledShillTitle>  
                <StyledShillText>
                By changing the S3 endpoint your application uses, S3X will allow you to use IPFS immediately. Only a single line change is required to achieve full IPFS capabilities seamlessly. Stream videos, host dynamic websites, build an FTP server and <a href="https://github.com/RTradeLtd/s3x/blob/master/README.md#end-to-end-example" style={{color:"orange"}}>much more</a>.
                </StyledShillText>    
              
            </StyledShill>


            <StyledShill>
            <StyledIconContainer style={{paddingTop:"3%"}}>
                    <img src={Nopin}/>
                </StyledIconContainer>
                <StyledShillTitle>Reference Counting Not Pinning</StyledShillTitle>  
                <StyledShillText>
                Spend seconds counting references, not hours walking DAGs.
                  </StyledShillText>        
            </StyledShill>



            <StyledShill>
            <StyledIconContainer style={{paddingBottom:"3%",paddingTop:"2%"}}>
                    <img src={Tune}/>
                </StyledIconContainer>
                <StyledShillTitle>Flexible Configurations</StyledShillTitle>  
                <StyledShillText>
                All systems are optional, ensuring you only run the services you need when you need them. You can disable/enable systems such as pubsub, and IPNS anytime at the click of a button.
                </StyledShillText>        
            </StyledShill>

        

            <StyledShill>
            <StyledIconContainer style={{paddingTop:"3%"}}>
                    <img src={Temporalx}/>
                </StyledIconContainer>
                <StyledShillTitle>TemporalX Dedicated Features</StyledShillTitle>  
                <StyledShillText>
                We will continue to release new functionalities specific to TemporalX. Your node will be able to simultaneously run in two or more different IPFS networks relaying data in between.   
                </StyledShillText>        
                <StyledCheckmarks>
                <StyledCheckmark>
                        <StyledCheckmarkContainer>
                            <img src={Checkmark}/>
                        </StyledCheckmarkContainer>
                        <StyledCheckmarkText >
                        <StyledLink href="https://medium.com/temporal-cloud/nodes-w-built-in-replication-high-performance-security-consensus-free-6657ac9e44ea" style={{color:"orange"}}>Built-In Data Replication</StyledLink>
                        </StyledCheckmarkText>
                    </StyledCheckmark>
                
                    <StyledCheckmark>
                        <StyledCheckmarkContainer>
                            <img src={Checkmark}/>
                        </StyledCheckmarkContainer>
                        <StyledCheckmarkText>
                        Multi-IPFS Network Capabilities
                        </StyledCheckmarkText>
                    </StyledCheckmark>

                    </StyledCheckmarks>
            </StyledShill>


        </StyledShillsContainer>
        </ProductTemporalXBG>
    )
}

export default sixShills
