import React from 'react'
import Layout from "../components/layout"
// import SEO from "../components/seo"
import ProductSection from '../components/product/temporalX/productTemporalX'
import MobileHeader from '../components/header/headerMobile'
import ProductPricing from '../components/product/temporal/productPricing'
import Carousel from '../components/testimonyCarousel/carousel'
import {Helmet} from "react-helmet"

const product = () => (
    <Layout>
        <div className="application">
        <Helmet>
          <meta charSet="utf-8" />
          <title>TemporalX</title>
          <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
          <meta property="og:title" content="TemporalX"/>
          <meta property="og:description" content="Your Enterprise IPFS Client"/>
          <meta property="og:image" content="https://temporal.cloud/temporalxProduct.png"/>
          <meta property="og:url" content="https://temporal.cloud/temporalx"/>
          <meta property="og:type" content="website"/>
          <meta property="og:locale" content="en_US"/>
          <meta name="twitter:card" content="summary_large_image"/>
          <meta name="twitter:site" content="@Temporalcloud"/>
          <meta name="twitter:title" content="TemporalX"/>
          <meta name="twitter:description" content="Your Enterprise IPFS Client"/>
          <meta name="twitter:image" content="https://temporal.cloud/temporalxProduct.png"/>
        
        </Helmet>
      </div>
    {/* <SEO title="TemporalX" /> */}
    <MobileHeader/>
    <ProductSection/>
    {/* <ProductPricing/> */}

     
 </Layout>
    )


export default product
