import React from 'react'
import styled from 'styled-components'
// import Header from '../../header/header'
import media from '../../../media'


import Speed from '../../../../images/product/temporalX/speed.svg'
import Checkmark from '../../../../images/product/temporalX/icons/checkmark.svg'





const StyledFeatureContainer = styled.div`
display:flex;
justify-content:space-around;
background-color:#212121;

${media.mobileS`
  flex-direction:column;
  align-items:center;
`}
${media.laptop`
flex-direction:row;
width:94%;
margin:auto;
/* padding:8% 0%; */
`}
`

const StyledFeatureHeader = styled.h1`
${media.mobileS`
width:100%;
font-size:1.5rem;
margin-top:100px;
`}
${media.laptop`
margin-top:10%;
padding-left:15%;

`}
color:orange;
`
const StyledFeatureBody = styled.p`

${
    media.mobileS`
   width:96%;
   font-size:0.9rem;
    `
  }
  ${
    media.tablet`
   width:80%;
   padding-left:15%;
   font-size:1rem;

    `
  }
  
color:white;
font-family:sans-serif;

`

const StyledLinks = styled.div`
display:flex;
flex-direction:row;
flex-wrap:wrap;
justify-content:space-around;
align-items:center;

${media.mobileS`
  width:100%;
`}
${media.laptop`
padding-left:15%;
padding-right:20%;
`}




`
const StyledLink = styled.a`
display:flex;
flex-direction:row;
justify-content:space-between;
align-items:stretch;
width:50%;
color:orange;
`
const StyledLinkText = styled.p`
${ media.mobileS`
   width:96%;
   font-size:0.9rem;
    `
  }
  ${media.tablet`
    width:80%;
   font-size:1rem;
    `
  }
  
color: orange;
font-family:sans-serif;
`
const StyledLinkContainer = styled.div`
width:35px;
` 
const StyledTextContent = styled.div`
display:flex;
flex-direction:column;
justify-content:space-around;
${media.mobileS`
padding-left:3%;
`}
${media.laptop`
padding-left:0;
`}
`
const StyledFeatureImageContainer = styled.div`

${media.mobileS`
width:80%;
padding:10% 0%;
`}
${media.laptop`
width:90%;
padding-right:10%;
`}
`

const publicPriavte = () => 
      ( 
        <StyledFeatureContainer>
            <StyledTextContent>
                <StyledFeatureHeader>
                    TemporalX Performance
                </StyledFeatureHeader>

                <StyledFeatureBody>
                As demonstrated by our benchmarks, TemporalX has consistent and predictable performance regardless of your workload. When leveraging the gRPC benefits, TemporalX can be up to 10 times faster than go-ipfs. When not leveraging gRPC benefits performance is 2-3 times faster than go-ipfs.           
                </StyledFeatureBody>

                <StyledLinks>
                    <StyledLink href="https://rtradeltd.github.io/TxPB/">
                        {/* <StyledLinkContainer>
                             <img src={Checkmark}/>
                        </StyledLinkContainer> */}
                        <StyledLinkText>
                        Documentation
                        </StyledLinkText>
                    </StyledLink>
                    <StyledLink href="https://medium.com/temporal-cloud/temporalx-vs-go-ipfs-official-node-benchmarks-8457037a77cf">
                        {/* <StyledLinkContainer>
                            <img src={Checkmark}/>
                        </StyledLinkContainer> */}
                        <StyledLinkText>
                        Benchmark Results
                        </StyledLinkText>
                    </StyledLink>
                </StyledLinks>
            </StyledTextContent>
            <StyledFeatureImageContainer>
                <img src={Speed}/>
            </StyledFeatureImageContainer>
        </StyledFeatureContainer>


      )
export default publicPriavte
