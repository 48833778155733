import React from 'react'
import TemporalXContactBG from '../temporal/productPricingBG'
import styled from 'styled-components'
import media from '../../media'





const StyledContactTitle = styled.a`
font-family:sans-serif;

${media.mobileS`
width:70%;
font-size:1rem;
/* padding-left:10%; */
`}
${media.laptop`
font-size:2rem;
/* margin:auto; */
text-align:center;
text-decoration:none;
width:100%;


`}
color:orange;
`
const StyledContactContainer = styled.div`
padding:10% 0 ;
padding-left:10%;
${media.mobileS`
width:70%;
margin:auto;

`}

${media.laptop`
width:100%;
padding:6% 0 ;
padding-left:28%;
`}
`


const TemporalXContact = () => (

        <TemporalXContactBG style={{backgroundColor:"#212121"}}>
            <StyledContactContainer >
            <StyledContactTitle href="https://t.me/RTradeTEMPORAL">
            Contact Us to Inquire About Add-on Pricing
            </StyledContactTitle>
            </StyledContactContainer>

        </TemporalXContactBG>
      )

    

export default TemporalXContact
