import React from 'react'
import styled from 'styled-components'
// import Header from '../../header/header'
import media from '../../../media'
import Features from '../../../../images/product/temporalX/features.svg'
import Checkmark from '../../../../images/product/temporalX/icons/checkmark.svg'




const StyledFeatureContainer = styled.div`
display:flex;
justify-content:space-around;
background-color:#212121;

${media.mobileS`
  flex-direction:column-reverse;
  align-items:center;
`}
${media.laptop`
  flex-direction:row;
  /* padding:5% 0%; */
`}


`

const StyledFeatureHeader = styled.h1`
${media.mobileS`
width:100%;
font-size:1.5rem;
/* margin-top:100px; */
`}
${media.laptop`
margin-top:10%;
padding-left:30%;

`}
color:orange;
`
const StyledFeatureBody = styled.p`

${
    media.mobileS`
   width:96%;
   font-size:0.9rem;
    `
  }
  ${
    media.tablet`
   width:85%;
   padding-left:30%;
   font-size:1rem;

    `
  }
  
color:white;
font-family:sans-serif;

`

const StyledLinks = styled.div`
display:flex;
flex-direction:row;
flex-wrap:wrap;
align-items:center;

${media.mobileS`
  width:100%;
  /* padding-left:5%; */
  justify-content:space-between;
`}
${media.laptop`
padding-left:30%;
padding-right:10%;
justify-content:space-around;
`}
`
const StyledLink = styled.a`
display:flex;
flex-direction:row;
justify-content:space-between;
align-items:stretch;

text-decoration:underline;
color: orange;
${media.mobileS`
width:47%;
`}
${media.laptop`
width:50%;
`}
`
const StyledLinkText = styled.p`
${ media.mobileS`
   width:96%;
   font-size:0.9rem;
    `
  }
  ${media.tablet`
    width:80%;
   font-size:1rem;
    `
  }
  
color: orange;
font-family:sans-serif;
`
const StyledLinkContainer = styled.div`
width:35px;
` 
const StyledTextContent = styled.div`
display:flex;
flex-direction:column;
justify-content:space-around;
${media.mobileS`
padding-left:3%;
`}
${media.laptop`
padding-left:0;
`}
`
const StyledFeatureImageContainer = styled.div`
width:70%;

${media.mobileS`
padding-left:0%;
`}
${media.laptop`
padding-left:10%;
`}

`

const Compatibility = () => (

        <StyledFeatureContainer>
            <StyledFeatureImageContainer>
                <img src={Features}/>
            </StyledFeatureImageContainer>

            <StyledTextContent>
                <StyledFeatureHeader>
                Features &amp; Compatibility
                </StyledFeatureHeader>

                <StyledFeatureBody>
                TemporalX's gRPC API is compatible in a wide variety of languages, including Golang, Python, JavaScript, Java, and more. The gRPC and protocol buffer definitions are open-source, which allows you to integrate with any project easily. 
               </StyledFeatureBody>

                <StyledLinks>
                    <StyledLink href="https://github.com/RTradeLtd/TxPB/tree/master/go">
                        {/* <StyledLinkContainer>
                             <img src={Checkmark}/>
                        </StyledLinkContainer> */}
                        <StyledLinkText>
                       Golang
                        </StyledLinkText>
                    </StyledLink>
                    <StyledLink href="https://github.com/RTradeLtd/TxPB/tree/master/js/">
                        {/* <StyledLinkContainer>
                            <img src={Checkmark}/>
                        </StyledLinkContainer> */}
                        <StyledLinkText>
                        Javascript
                        </StyledLinkText>
                    </StyledLink>
                    <StyledLink href="https://github.com/RTradeLtd/TxPB/tree/master/py">
                        {/* <StyledLinkContainer>
                             <img src={Checkmark}/>
                        </StyledLinkContainer> */}
                        <StyledLinkText>
                       Python
                        </StyledLinkText>
                    </StyledLink>
                    <StyledLink href="https://github.com/RTradeLtd/TxPB/tree/master/java/pb/">
                        {/* <StyledLinkContainer>
                            <img src={Checkmark}/>
                        </StyledLinkContainer> */}
                        <StyledLinkText>
                        Java
                        </StyledLinkText>
                    </StyledLink>

                </StyledLinks>
            </StyledTextContent>
         
        </StyledFeatureContainer>


      )

export default Compatibility
